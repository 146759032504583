import React from 'react';
import '../../styles/temp.css';

const Footer = React.forwardRef((_props, ref) => {
   return (
      <footer role="definition" id="footer" className="bg-gray-800 " ref={ref}>
         <div
            className="max-md:!py-[2rem] max-md:!flex-col max-md:!gap-5 layout gutter-x text-white min-h-[8vh] bg-black flex flex-row items-center justify-between">
            <div className="text-sm text-center">
               © <span id="currentYear"></span> Unizo Inc. All rights reserved.
            </div>
            <div className="flex flex-row gap-2 ">
               <a target="_blank" className="hover:border-white hover:text-[var(--app-theme)] flex justify-center items-center rounded-full border-[1.5px] border-[transparent] p-[.7rem]"
                  href="#">
                  <i className="fa-brands fa-linkedin"></i>
               </a>
               <a target="_blank" className="hover:border-white hover:text-[var(--app-theme)] flex justify-center items-center rounded-full border-[1.5px] border-[transparent] p-[.7rem]"
                  href="#">
                  <i className=" fa-brands fa-x-twitter"></i>
               </a>
               <a target="_blank" className="hover:border-white hover:text-[var(--app-theme)] flex justify-center items-center rounded-full border-[1.5px] border-[transparent] p-[.7rem]"
                  href="#">
                  <i className=" fa-brands fa-youtube"></i>
               </a>
            </div>
         </div>
      </footer>
   )
})

const Header = React.forwardRef((props, ref) => {
   return (
      <div
         className="sticky top-0 z-[10] layout gutter-x justify-between flex flex-row items-center bg-white"
         id="app-header"
         role="navigation"
         {...props}
         ref={ref}
      >
         <div className="app-header  flex-smax-lg:justify-between max-lg:px-5 gap-2" aria-describedby="header">
            <a role="link" href="index" aria-describedby="org_logo">
               <img width="50px" src="https://www.1tegrate.com/onetegratelogo.webp" alt="No image found" />
            </a>
            <p className="h4  flex-[1_0]" style={{ flex: 1, marginTop: '1rem' }}>Unizo</p>
            <div className="flex items-center justify-start flex-row gap-4 ">

            </div>
         </div>
         <div className='flex flex-row items-center'>
            <a href="/login">
               <button title='log In' className="text-blue-700 underline flex items-center gap-2">Sign In <i className="fas fa-user"></i></button>
            </a>
            <a href="/signup">
               <button title="Register" style={{ borderRadius: '5px' }} className="bg-blue-700 lg:p-0 flex flex-row gap-2 items-center p-3 !hover:bg-blue-800 text-white">
                  Register
                  <i className="fas fa-arrow-right"></i>
               </button>
            </a>
         </div>
      </div>
   )
})

const Content = React.forwardRef((props, ref) => {
   return (
      <div id="ew-home" {...props} ref={ref}>
         <div>
            <section className="layout gutter-x py-9 css-1ldt3te">
               <div className="flex flex-col items-start gap-4 w-[100%] max-lg:!w-[100%] max-md:!w-full">
                  <div className="flex flex-col items-start justify-enter">
                     <p className="h1 font-normal"> <span className='space-x-1'> About </span>
                        <span className="colored-text-orange-1">Us</span>
                     </p>
                  </div>
                  <span className="h6 font-normal">
                     Unizo is on mission to empower enterprise SaaS providers with
                     a game-changing solution that streamlines the 3rd party
                     integration process, accelerates time-to-market, and drives
                     business growth. We envision a world where integrations are no
                     longer a barrier to success but instead a catalyst for growth and
                     innovation.
                  </span>
               </div>
            </section>
            <section role="article" className="layout gutter-x">
               <div className="flex flex-row max-lg:!flex-col  max-lg:!items-center gap-[5rem]">
                  <div className="pt-[3rem] max-md:py-[0rem] flex flex-col items-start gap-7">
                     <div className="flex flex-col items-start justify-center max-lg:text-[2rem] font-[700]">
                        <p className="h1 font-normal ">What is a</p>
                        <span className="h2 colored-text-orange-1">
                           Embedded Integration
                        </span>
                     </div>
                     <div className="h6 font-normal text-justify">
                        Embedded Integration Platform designed to streamline the integration process for developers, businesses, and IoT enthusiasts alike. Our platform empowers you to seamlessly embed, connect, and manage diverse applications and devices, enabling you to create innovative solutions with ease.
                     </div>

                  </div>
                  <img width="450"
                     className="max-lg:w-[450px] max-sm:!w-[300px]"
                     src={'/images/classic/index-1.webp'}
                     alt="banner_2_img"
                  />
               </div>
            </section>
            <section className="layout gutter-x py-9">
               <div className="flex flex-col items-start gap-4 w-[100%] max-lg:!w-[100%] max-md:!w-full">
                  <div className="flex flex-col items-start justify-enter">
                     <p className="h1 font-normal"> Join <span className="colored-text-orange-1">Us </span></p>
                  </div>
                  <span className="h6 font-normal">
                     Are you ready to revolutionize the way enterprise SaaS providers
                     approach integrations? Do you thrive in fast-paced, innovative
                     environments where your ideas can make a real impact? If so, we
                     want to hear from you! While we may be in stealth mode, we're
                     always on the lookout for talented individuals who share our
                     passion for innovation and are eager to join us on our journey.
                  </span>
               </div>
            </section>
            <section className="layout gutter-x py-9">
               <div className="flex flex-col items-start gap-4 w-[100%] max-lg:!w-[100%] max-md:!w-full">
                  <div className="flex flex-col items-start justify-enter">
                     <p className="h1 font-normal"> Join <span className="colored-text-orange-1">Connected </span></p>
                  </div>
                  <span className="h6 font-normal">
                     While we continue to develop our groundbreaking solution, we
                     invite you to stay connected with us. Sign up for our newsletter,
                     follow us on social media, and be the first to know about exciting
                     updates, announcements, and opportunities to get involved.<br /><br />
                     Thank you for your interest in Unizo Inc. Together, let's unlock
                     the power of seamless integrations and pave the way for a future
                     of innovation and growth.
                  </span>
               </div>
            </section>
         </div>
      </div>
   )
})

// todo
const HomePage = React.forwardRef((props, ref) => {
   return (
      <div {...props} ref={ref}>
         <Header />
         <Content />
         <Footer />
      </div>
   )
})

export default HomePage;